<template>

  <jl-form
    :columns="searchColumns"
    inline="true"
    confirm="query"
    cancel="reset"
    @onSubmit="onSearchSubmit"
    @resetForm="resetForm"
    v-if="searchColumns.length > 0"
  ></jl-form>
  <!-- 操作按钮 start -->
  <div class="text-right">
    <el-button
      v-if="options.isAdd"
      class="el-button--add"
      icon="el-icon-plus"
      round
      @click="handleAction('')"
    >
      {{ $t("i18n.new") }}
    </el-button>
    <el-button
      v-if="options.BatchAudit"
      type="primary"
      class="el-button--add"
      round
      :disabled="!options.disabledBatchAudit"
      @click="handleBatch"
    >
      {{ $t("i18n.batchAudit") }}
    </el-button>
    <template v-for="btn in operates.list">
      <el-button
        v-if="btn.show && btn.type === 'outer'"
        :key="btn.id"
        :class="'el-button--' + btn.class"
        :icon="btn.icon"
        :loading="btn.loading"
        round
        @click="btn.method(formInline)"
      >
        <template v-if="btn.formatter">
          <span v-html="btn.formatter(btn.label)"></span>
        </template>
        <template v-else>
          {{ $t("i18n." + btn.label) }}
        </template>
      </el-button>
    </template>
  </div>
  <!-- end -->
  <el-table
    class="jlTable"
    :data="tableData"
    ref="multipleTable"
    row-key="id"
    :max-height="height"
    :row-class-name="tableRowClassName"
    @selection-change="handleSelectionChange"
  >
    <!-- 多选框 start -->
    <el-table-column
      v-if="options.multiSelect"
      type="selection"
      style="width: 55px"
      :reserve-selection="true"
    ></el-table-column>
    <!--end-->
    <!-- 序列框 -->
    <!-- 数据列 start -->
    <template v-for="(column, index) in columns" :key="index">
      <el-table-column
        :prop="column.prop"
        :label="column.label ? $t('i18n.' + column.label) : ''"
        :align="column.align"
        :width="column.width"
        :fixed="index < 2 && $defined.WIDTH > 768"
        :show-overflow-tooltip="true"
        v-if="column.rows !== false"
      >
        <template #default="scope">
          <template v-if="!column.render">
            <template v-if="column.formatter">
              <span
                v-if="column.method"
                @click.prevent="column.method(scope.row, formInline)"
                v-html="column.formatter(scope.row, column)"
              ></span>
              <span v-else v-html="column.formatter(scope.row, column)"></span>
            </template>
            <template v-else>
              <span
                v-if="
                  scope.row[column.prop] === '' ||
                  scope.row[column.prop] === null
                "
              >
                -
              </span>
              <div v-else-if="column.edit">
                <el-popover
                  placement="right"
                  :width="400"
                  trigger="click"
                  @after-enter="value[column.prop] = scope.row[column.prop]"
                  @hide="value[column.prop] = ''"
                  popper-class="update"
                >
                  <el-date-picker
                    v-if="column.type === 'date'"
                    v-model="value[column.prop]"
                    align="right"
                    type="date"
                  ></el-date-picker>
                  <el-input
                    autofocus
                    v-else
                    v-model="value[column.prop]"
                    clearable
                  ></el-input>
                  <el-button
                    @click="
                      column.method(column.prop, value[column.prop], scope.row)
                    "
                  >
                    {{ $t("i18n.confirm") }}
                  </el-button>
                  <template #reference>
                    <el-button>{{ scope.row[column.prop] }}</el-button>
                  </template>
                </el-popover>
              </div>
              <span v-else>{{ scope.row[column.prop] }}</span>
            </template>
          </template>
          <template v-else>
            <expand-dom
              :column="column"
              :row="scope.row"
              :render="column.render"
              :index="index"
            ></expand-dom>
          </template>
        </template>
      </el-table-column>
    </template>
    <!-- end -->
    <!-- 按钮操作组 start -->
    <el-table-column
      ref="fixedColumn"
      :label="$t('i18n.operation')"
      align="center"
      :width="operates.width"
      :fixed="operates.fixed"
      v-if="
        operates.list.filter((_x) => _x.show === true && _x.type === 'inner')
          .length > 0 ||
        options.isEdit ||
        options.isDelete
      "
    >
      <template #default="scope">
        <div class="operate-group">
          <div class="item" v-if="options.isEdit">
            <el-button
              size="mini"
              icon="el-icon-edit"
              @click="handleAction(scope.row)"
            >
              {{ $t("i18n.edit") }}
            </el-button>
          </div>
          <div class="item" v-if="options.isDelete">
            <el-button
              size="mini"
              icon="el-icon-delete"
              @click="handleDel(scope.row)"
            >
              {{ $t("i18n.delete") }}
            </el-button>
          </div>
          <template v-for="(btn, key) in operates.list">
            <div
              class="item"
              v-if="btn.show && btn.type === 'inner'"
              :key="btn.id"
            >
              <el-button
                :class="btn.class"
                size="mini"
                :icon="btn.icon"
                :disabled="btn.disabled"
                :plain="btn.plain"
                @click.prevent="btn.method(key, scope.row, formInline)"
              >
                <template v-if="btn.formatter">
                  <span v-html="btn.formatter(scope.row)"></span>
                </template>
                <template v-else>
                  {{ $t("i18n." + btn.label) }}
                </template>
              </el-button>
            </div>
          </template>
        </div>
      </template>
    </el-table-column>
    <!-- end -->
  </el-table>
  <div v-if="options.pageShow && total > 0" class="block m-t text-center">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formInline.page"
      :page-sizes="[10, 20, 30, 50]"
      :page-size="formInline.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
  <el-dialog :title="title" v-model="dialogFormVisible" destroy-on-close>
    <jl-form
      :columns="rowColumns"
      @onSubmit="onSubmit"
      @resetForm="onCancel"
    ></jl-form>
  </el-dialog>
</template>
<script>
import jlForm from "../components/form";
import {
  reactive,
  toRefs,
  h,
  getCurrentInstance,
  watch,
  ref,
  onActivated,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
export default {
  name: "table",
  props: {
    // 数据列表
    tableData: {
      type: Array,
      default: () => [],
    },
    columns: {
      // 需要展示的列 === prop：列数据对应的属性，label：列名，align：对齐方式，width：列宽 search：搜索
      type: Array,
      default: () => [],
    },
    operates: {}, // 操作按钮组 === label: 文本，type :类型（primary / success / warning / danger / info / text），show：是否显示，icon：按钮图标，plain：是否朴素按钮，disabled：是否禁用，method：回调方法
    options: {
      // table 表格的控制参数
      type: Object,
      default: () => ({
        highlightCurrentRow: false, // 是否要高亮当前行
      }),
    },
    total: {
      // 所有条数
      type: Number,
      default: () => 0,
    },
    height: {
      type: String,
      default: () => "100px",
    },
    selectDefault: {
      // 默认选中值
      type: Array,
      default: () => [],
    },
  },
  components: {
    //组件
    jlForm,
    expandDom: {
      functional: true,
      props: {
        row: Object,
        render: Function,
        index: Number,
        column: {
          type: Object,
          default: null,
        },
      },
      render: (f, p, ctx) => {
        const params = {
          row: ctx.row,
          index: ctx.index,
        };
        if (ctx.column) params.column = ctx.column;
        return ctx.render(h, params);
      },
    },
  },
  emits: [
    "handleSelectionChange",
    "handleEdit",
    "init",
    "handleDelete",
    "getEditData",
  ],
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const { proxy } = getCurrentInstance();
    const multipleTable = ref(null);
    const state = reactive({
      value: {},
      pageIndex: 1,
      multipleSelection: [], // 多行选中
      formInline: {
        page: 1,
        pageNumber: 1,
        pageSize: 10,
      },
      dialogFormVisible: false,
      form: {},
      title: "",
      editId: "",
      searchColumns: [],
      rowColumns: [],
      scrollY: 0,
    });

    onActivated(() => {
      if (route.meta.isNotKeepAlive === true) {
        state.formInline.page = 1;
        state.formInline.pageNumber = 1;
      } else {
        document
          .querySelector(".el-table__body-wrapper")
          .addEventListener("scroll", scrollTop);
        document.querySelector(".el-table__body-wrapper").scrollTop =
          state.scrollY;
      }
      emit("init", state.formInline);
    });

    const scrollTop = () => {
      state.scrollY = document.querySelector(
        ".el-table__body-wrapper"
      ).scrollTop;
    };

    const handleSelectionChange = (val) => {
      state.multipleSelection = val;
      emit("handleSelectionChange", val);
    };
    const handleBatch = () => {
      emit("handleBatch", true);
    };
    const handleSizeChange = (val) => {
      state.formInline.page = 1;
      state.formInline.pageNumber = 1;
      state.formInline.pageSize = val;
      emit("init", state.formInline);
    };

    const onSearchSubmit = (val) => {
      for (let key in val) {
        state.formInline[key] = val[key];
      }
      handleCurrentChange(1);
    };

    // const handlesort = (sort) => {
    //   state.formInline.sortOrder = sort.order.replace("ending", "");
    //   state.formInline.sortName = proxy.$defined.toLowerLine(sort.prop) ;
    //   handleCurrentChange(1);
    // };

    const handleCurrentChange = (val) => {
      state.formInline.page = val;
      state.formInline.pageNumber = val;
      emit("init", state.formInline);
    };

    const onSubmit = (val) => {
      for (let key in val) {
        state.form[key] = val[key];
      }
      state.form.id = state.title === "编辑" ? state.editId : "";
      emit("handleEdit", state.form, state.formInline);
      state.dialogFormVisible = false;
    };

    const onCancel = () => {
      state.dialogFormVisible = false;
    };

    const resetForm = () => {
      state.formInline = {
        page: 1,
        pageNumber: 1,
        pageSize: 10,
      };
      emit("init", state.formInline);
    };
    const clearSelection = () =>{
      multipleTable.value.clearSelection()
    }
    const tableRowClassName = ({ row }) => {
      if (row.inUse == "0") {
        return "forbid-row";
      } else if (row.select === true) {
        return "select-row";
      }
      return "";
    };

    // 新增/编辑数据
    const handleAction = (rows) => {
      state.editId = rows.id || "";
      props.columns.map((item) => {
        item.isUpdate = rows ? true : false;
        item.value = rows === "" ? "" : rows[item.prop];
      });
      state.title = rows ? t("i18n.edit") : t("i18n.new");
      state.dialogFormVisible = true;
      emit("getEditData", rows);
    };

    const handleDel = (rows) => {
      proxy.$defined.confirm(
        () => {
          emit("handleDelete", rows, state.formInline);
        },
        t("i18n.doYouWantToDelete"),
        t("i18n.delete")
      );
    };
    watch(
      () => props.options.deleteDisabled,
      () => {
        clearSelection();
      },
      {
        deep: true,
      }
    );

    watch(
      () => props.columns,
      () => {
        //通过一个函数返回要监听的属性
        initColumns();
      },
      {
        deep: true,
      }
    );

    watch(
      () => props.selectDefault,
      () => {
        props.selectDefault.forEach((row) => {
          var index = props.tableData.findIndex((item) => {
            return item.id === row.id;
          });
          if (index > -1) {
            multipleTable.value.toggleRowSelection(props.tableData[index]);
          }
        });
      },
      {
        deep: true,
      }
    );

    const initColumns = () => {
      let search = props.columns.filter((_x) => _x.search === true);
      let row = props.columns.filter((_x) => _x.row !== false);
      if (state.title === "") {
        state.searchColumns = copy(search);
      }
      state.rowColumns = copy(row);
      state.searchColumns.map((item) => {
        if (item.value === false) {
          item.value = false;
        } else {
          item.value = route.query[item.prop] || item.value || "";
        }
        state.formInline[item.prop] = item.value || state.formInline[item.prop];
        if (item.type === "remote") {
          item.type = "input";
        }
        delete item.rules;
        delete item.formatter;
      });
    };

    const copy = (data) => {
      var newData = [];
      data.map((item) => {
        var obj = {};
        for (let key in item) {
          obj[key] = item[key];
        }
        newData.push(obj);
      });
      return newData;
    };

    initColumns();
    emit("init", state.formInline);
    return {
      ...toRefs(state),
      handleSelectionChange,
      handleBatch,
      handleSizeChange,
      handleCurrentChange,
      onSearchSubmit,
      onSubmit,
      resetForm,
      handleAction,
      handleDel,
      tableRowClassName,
      onCancel,
      multipleTable,
      clearSelection,
    };
    
  },
};
</script>
<style lang="scss">
.jlTable {
  width: 100%;
  height: 100%;
  .operate-group {
    .item {
      display: inline-block;
      &:not(:first-child) {
        margin-left: 15px;
      }
      .el-button {
        border: none;
        background: transparent;
        color: $blue_color;
        font-size: 14px;
        padding: 0;
      }
    }
  }
  .forbid-row {
    * {
      color: #ccc !important;
    }
  }
  .select-row {
    background: #ecf5ff;
  }
}
.el-popover.update {
  .el-input {
    width: 2 * $width + 90;
  }
  .el-button {
    border: none;
    background: $blue_color;
    color: $white;
    margin-left: 5px;
  }
}
</style>
